module.exports = {
  pathPrefix: `/`, // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "/portfolio"

  siteTitle: `Tarun Sharma`, // Navigation and Site Title
  siteTitleAlt: `Tarun Sharma - Software Engineer and Designer`, // Alternative Site title for SEO
  siteTitleShort: `Tarun Sharma`, // short_name for manifest
  siteHeadline: `Software Engineer and Designer`, // Headline for schema.org JSONLD
  siteUrl: `https://apps.tkssharma.com`, // Domain of your site. No trailing slash!
  siteKeywords: [`Tarun Sharma`, `fullstack`, `software`, `engineer`, `developer`, `design`, `portfolio`, `blog`, `nestjs`, `javascript`, `react`],
  siteLanguage: `en`, // Language Tag on <html> element
  siteLogo: `/logo.png`, // Used for SEO and manifest
  siteDescription: `Tarun Sharma. Full-stack software engineer and designer with a passion for using technology to create unique and memorable experiences`,
  author: {
    name: `Tarun Sharma`,
    bio: `Software engineer and designer with a passion for exploring new ideas, platforms, and web-technologies, while creating tools that myself and other enjoy.`,
    github: `https://github.com/tkssharma`,
    dribbble: `https://tkssharma.com`,
    twitter: `https://twitter.com/tkssharma`,
    linkedIn: `https://www.linkedin.com/in/tarun-sharma-03790254/`,
  },
  menuLinks: [
    {
      name: `Home`,
      link: `/`,
      external: false,
    },
    {
      name: `Videos`,
      link: `/blogs/`,
      external: false,
    },
    {
      name: `Blog`,
      link: `/blog/`,
      external: false,
    },
    {
      name: `Contact`,
      link: `/contact/`,
      external: false,
    },
  ],
  twitterUsername: `@tkssharma`, // Twitter Username
  // Manifest and Progress color
  backgroundColor: `#23262B`,
}
